import React, { FC } from 'react';

import { ISoBanner, Variants } from './models.d.ts';

import { ColumnContainer, DefaultContainer, YoutubeVideoContainer } from './variants/containers';

import './SoBanner.scss';

const SoBanner: FC<ISoBanner> = ({ data }) => {
  switch (data?.containerVariant[0]) {
    case Variants.column:
      return <ColumnContainer {...{ data }} />;
    case Variants.Video:
      return <YoutubeVideoContainer {...{ data }}/>;
    default:
      return <DefaultContainer {...{ data }} />;
  }
};

export default SoBanner;
