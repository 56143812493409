import React, { FC } from 'react';
import VideosYoutube from '../../../VideosYoutube';
import { ISoBanner } from '../../models';

const DefaultContainer: FC<ISoBanner> = ({ data }) => {
  const { extraClass } = data;

  return <VideosYoutube {...{ extraClass }} soBanner items={data.items} lazyLoad={false} />;
};

export default DefaultContainer;
