import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import YouTubeLazyLoaded from '../YouTubeLazyLoaded';
import { VideosYoutubeProps } from './models';

import './VideosYoutube.scss';

const VideosYoutube: FC<VideosYoutubeProps> = ({
  videoList,
  videosTitle,
  videosSubtitle,
  soBanner = false,
  items,
  extraClass,
  lazyLoad = true,
}) => {
  return (
    <div className={`banner__video ${extraClass || ''}`}>
      {soBanner && items ? (
        <>
          {items.map(({ properties }) => (
            <div key={properties.video}>
              <DangerouslySetInnerHtml html={properties.text} />
              <YouTubeLazyLoaded
                videoId={properties.video}
                videoPoster={properties.coverVideo}
                lazyLoad={lazyLoad}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          {' '}
          <h1 className="video-page__title">{videosTitle}</h1>
          <DangerouslySetInnerHtml className="video-page__text" html={videosSubtitle} />
          <div className="video__content">
            {videoList &&
              videoList.map(({ videoId, name, imagePoster }) => {
                return (
                  <div className="video__item" key={videoId}>
                    <YouTubeLazyLoaded videoId={videoId} videoPoster={imagePoster} />
                    <p className="video__title">{name}</p>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default VideosYoutube;
