export interface SobannerProps {
  data: SoBanner;
  index?: number;
}

export interface IBanner {
  bannerHalf: ResponsiveImage;
  bannerMain: ResponsiveImage;
  columnAlign: [string];
  image: ResponsiveImage;
  legalText: string;
  text: string;
  extraClass: string;
  buttonType: string;
  button: LinkType;
  altTextBannerMain: string;
  altTextBannerHalf: string;
  altTextColumnImage: string;
}

export interface ISoBanner {
  data: IColumnContainer;
}
export interface IColumnContainer {
  extraClass: string;
  containerVariant: [String];
  items: [IBanner];
}

type SoBanner = {
  title: String;
  subtitle: String;
  extraClass: String;
  containerVariant: String;
  items: [SoBannerItem];
  showPager: String;
  showThumbs: String;
  showArrows: String;
  showStatus: String;
  infiniteLoop: String;
  containerText: String;
  legalText: String;
  containerImage?: ResponsiveImage;
  imagePage?: UmbracoImage | null;
  pageSize?: number;
};

type SoBannerItemProps = {
  data: SoBannerItem;
  index: number;
};

type SoBannerItem = {
  imageDesktop?: ResponsiveImage;
  imageMobile?: ResponsiveImage;
  imageProduct?: ResponsiveImage;
  imageRecipe?: ResponsiveImage;
  extraClass: String;
  legalText: String;
  bannerText: String;
  button: LinkType;
  buttonType: String;
  variant: String;
};

type SoBannerPageProps = {
  items: [SoBannerItem];
  indexPage: number;
};

export enum Variants {
  column = 'Column',
  default = 'Default',
  Video = 'Video',
}

export enum Components {
  BannerHalf = 'Banner Half',
  BannerMain = 'Banner Main',
  Column = 'Column',
}
