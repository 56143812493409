import React, { FC } from 'react';
import Components from '../items/Components';
import { ISoBanner } from '../../models';

const ColumnContainer: FC<ISoBanner> = ({ data }) => {
  return (
    <div className="soBanner soBanner--column">{data.items.map((block) => Components(block))}</div>
  );
};

export default ColumnContainer;
