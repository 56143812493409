import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import UmbracoImage from 'gatsby-theme-husky/src/common/Image/UmbracoImage';
import { IBanner } from '../../models';

const BannerMainVariant: FC<IBanner> = ({
  bannerMain,
  extraClass,
  altTextBannerMain,
  text,
  legalText,
  buttonType,
  button,
}) => {
  return (
    <div className={`soBanner--main  ${extraClass}`}>
      {bannerMain && (
        <UmbracoImage
          lazyLoad={ false }
          image={bannerMain}
          alt={altTextBannerMain}
          className="soBanner__image banner--with-image-as-background__image"
        />
      )}
      <div className={`soBanner__content${bannerMain ? `` : '--full'}`}>
        {text && <DangerouslySetInnerHtml html={text} className="banner__text" />}
        {button && (
          <a href={button.url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
            {button.name}
          </a>
        )}
      </div>

      {legalText && (
        <div className="soBanner__legalText">
          <DangerouslySetInnerHtml html={legalText} />
        </div>
      )}
    </div>
  );
};

export default BannerMainVariant;
