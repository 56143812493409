import React from 'react';
import { BannerHalfVariant, BannerMainVariant, ColumnVariant } from './index';
import { Components } from '../../models.d.ts';

export default (block) => {
  switch (block.properties.variant[0]) {
    case Components.BannerHalf:
      return React.createElement(BannerHalfVariant, {
        key: 1,
        ...block.properties,
      });
    case Components.BannerMain:
      return React.createElement(BannerMainVariant, {
        key: 2,
        ...block.properties,
      });
    case Components.Column:
      return React.createElement(ColumnVariant, {
        key: 3,
        ...block.properties,
      });
    default:
      return null;
  }
};
