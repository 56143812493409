import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'gatsby-theme-husky/src/layout/Layout';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import PageSchema from 'gatsby-theme-husky/src/common/PageSchema';
import createBreadcrumbs from 'gatsby-theme-husky/src/utils/createBreadcrumbs';
import AboutUsHeader from 'gatsby-theme-husky/src/components/AboutUsHeader';
import ProductsCategoryList from 'gatsby-theme-husky/src/components/Products/ProductsCategoryList';
import Suggestions from 'gatsby-theme-husky/src/components/Suggestions';
import SoBanner from '../../components/SoBanner';

import './AboutUsOverride.scss';

const AboutUsPage: FC<AboutUsTypes.AboutUsProps> = ({
  data: {
    allUmbracoAboutUs: { nodes: umbracoAboutUs },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    aboutUsHeader,
    productsCategoryList,
    aboutUsSuggestions,
    content,
  } = umbracoAboutUs[0];

  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname),
  };

  return (
    <Layout breadcrumbs={breadcrumbs} langProps={{ urls, lang }} className="about-us-page">
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {content?.map(({ properties }, index) => (
        <SoBanner data={properties} key={index} />
      ))}
      {aboutUsHeader ? <AboutUsHeader aboutUsHeaderNodes={aboutUsHeader[0]} /> : null}
      {aboutUsSuggestions ? <Suggestions suggestionsItemNodes={aboutUsSuggestions[0]} /> : null}
      {productsCategoryList !== null ? (
        <ProductsCategoryList productsCategoryList={productsCategoryList[0]} withSlider />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query AboutUsQuerys($pageId: String) {
    allUmbracoAboutUs(filter: { id: { eq: $pageId } }) {
      nodes {
        content {
          properties {
            containerVariant
            items {
              properties {
                variant
                extraClass
                bannerMain {
                  fallbackUrl
                  fluid {
                    srcSet
                    originalImg
                    base64
                  }
                  variants {
                    url
                    type
                    fallbackQuery
                    alias
                  }
                }
                altTextBannerMain
                bannerHalf {
                  fallbackUrl
                  fluid {
                    srcSet
                    originalImg
                    base64
                  }
                  variants {
                    url
                    type
                    fallbackQuery
                    alias
                  }
                }
                altTextBannerHalf
                columnAlign
                image {
                  fallbackUrl
                  fluid {
                    srcSet
                    originalImg
                    base64
                  }
                  variants {
                    url
                    type
                    fallbackQuery
                    alias
                  }
                }
                altTextColumnImage
                text
                buttonType
                button {
                  name
                  url
                }
                legalText
              }
            }
          }
        }
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        aboutUsHeader {
          aboutUsTitle
          aboutUsDescription
          aboutUsSignature
        }
        productsCategoryList {
          allProductsText
          allProductsLink {
            url
          }
          productsTitle
          productsDescription
          productContent {
            boxTitle
            productButton {
              ariaLabel
              link {
                url
              }
              text
              withReverse
            }
            productImage {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            productImageAlt
          }
        }
        aboutUsSuggestions {
          suggestionsListTitle
          suggestionsList {
            suggestionImage {
              ...umbracoImage
            }
            suggestionTitle
            suggestionDescription
          }
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default AboutUsPage;
