import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import UmbracoImage from 'gatsby-theme-husky/src/common/Image/UmbracoImage';
import { IBanner } from '../../models';

const BannerHalfVariant: FC<IBanner> = ({
  bannerHalf,
  altTextBannerHalf,
  buttonType,
  button,
  extraClass,
  legalText,
  text,
}) => {
  return (
    <div className={`row ${extraClass || ''}`}>
      <div className="soBanner soBanner--halfBanner col--sm12 col--md10">
        {bannerHalf && (
          <UmbracoImage
          lazyLoad={ false }
            image={bannerHalf}
            alt={altTextBannerHalf}
            className="soBanner__image banner--with-image-as-background__image"
          />
        )}
        <div className="soBanner__content">
          {text && <DangerouslySetInnerHtml html={text} className="banner__text" />}
          {button && (
            <a href={button.url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
              {button.name}
            </a>
          )}
        </div>

        {legalText && (
          <div className="soBanner__legalText">
            <DangerouslySetInnerHtml html={legalText} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerHalfVariant;
