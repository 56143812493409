import React, { FC } from 'react';
import UmbracoImage from 'gatsby-theme-husky/src/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import { IBanner } from '../../models';

const ColumnVariant: FC<IBanner> = ({
  buttonType,
  button,
  columnAlign,
  extraClass,
  image,
  altTextColumnImage,
  legalText,
  text,
}) => {
  return (
    <div className={`row align-items-center column--${columnAlign || ''} ${extraClass || ''}`}>
      <div className="column__image col--sm12 col--md6">
        {image && (
          <UmbracoImage
            lazyLoad={ false }
            image={image}
            alt={altTextColumnImage}
            className="banner__image banner--with-image-as-background__image"
          />
        )}
      </div>
      <div className="column__content col--sm12 col--md6">
        {text && <DangerouslySetInnerHtml html={text} className="banner__text" />}
        {button && (
          <a href={button.url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
            {button.name}
          </a>
        )}
      </div>
      <div className="column__legalText">
        {legalText && <DangerouslySetInnerHtml html={legalText} />}
      </div>
    </div>
  );
};

export default ColumnVariant;
